<template>
  <ReportBase title="Response Translation" :is-loading="isLoading" class="response-translation">
    <template #afterTitle>
      <DropdownWrapper class="response-translation__dropdown" name="contentOptions" :options="coversationOptions"
        v-model="currentContent" :searchable="false" no-clear no-border />
    </template>

    <template #content>
      <div class="flex flex-col h-full">
        <div v-if="cardContent && cardContent.length" class="flex gap-3">
          <div v-for="cardData in cardContent" class="info-card">
            <div v-for="cardInfo in cardData" class="flex justify-between items-center">
              <p class="info-card__title">{{ cardInfo.title }}</p>
              <div class="flex items-end">
                <p class="info-card__value-absolute" v-if="cardInfo.absolute">{{ cardInfo.absolute }}</p>
                <p class="info-card__value-percent" v-if="cardInfo?.percent">{{ cardInfo.percent }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1 h-full mt-4">
          <TableContent v-if="tableContent" :columnDefs="tableContent.columnDefs"
            :defaultColDef="tableContent.defaultColDef" :rowData="tableContent.rowData" />
        </div>
      </div>
    </template>
  </ReportBase>
</template>

<script>
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";
import NotifyMixin from '@/mixins/NotifyMixin';

import DropdownWrapper from '@/components/report/common/DropdownWrapper.vue';
import ReportBase from '@/components/report/tabular/ReportBase.vue';
import TableContent from '@/components/report/tabular/TableContent.vue';
import { getKeyValue, responseToTable } from '@/components/report/tabular/cubeDataHandler';

const REPORT_IDS = {
  STANDARD_RESPONSES: {
    table: 'DHR04_4',
    card: 'DHR04_3'
  },
  GLOBAL_RESPONSES: {
    table: 'DHR04_2',
    card: 'DHR04_1'
  }
}

export default {
  name: 'MyConversation',

  mixins: [NotifyMixin, CubeRequestMixin],

  components: {
    TableContent,
    ReportBase,
    DropdownWrapper,
  },

  data() {
    return {
      isLoading: false,
      tableContent: null,
      cardContent: null,
      currentContent: null,
      coversationOptions: [
        {
          value: 'Standard responses',
          key: 'STANDARD_RESPONSES'
        },
        {
          value: 'Global Responses',
          key: 'GLOBAL_RESPONSES'
        },
      ]
    }
  },

  watch: {
    currentContent() {
      this.fetchContent()
    }
  },

  methods: {
    async fetchContent() {
      this.isLoading = true;

      try {
        const [tableResponse, cardResponse] =
          await Promise.all(
            [
              this.fetchCubeData(REPORT_IDS[this.currentContent].table),
              this.fetchCubeData(REPORT_IDS[this.currentContent].card)
            ]
          )

        this.tableContent = responseToTable(tableResponse);
        this.cardContent = this.cardContenHandler(cardResponse)
        this.isLoading = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    cardContenHandler(cubeData) {
      if (!cubeData?.data.length || !cubeData?.annotation?.measures) {
        return null;
      }

      return [
        [
          {
            title: getKeyValue(cubeData.annotation.measures, 'totalResponses')?.shortTitle || 'total responses',
            absolute: getKeyValue(cubeData.data[0], 'totalResponses'),
          },
          {
            title: getKeyValue(cubeData.annotation.measures, 'translatedResponses')?.shortTitle || 'translated responses',
            absolute: getKeyValue(cubeData.data[0], 'translatedResponses'),
            percent: `${getKeyValue(cubeData.data[0], 'translatedResponsesPercentage') || '0.0'}%`
          }
        ],
        [
          {
            title: getKeyValue(cubeData.annotation.measures, 'outdatedTranslations')?.shortTitle || 'outdated translations',
            absolute: getKeyValue(cubeData.data[0], 'outdatedTranslations'),
            percent: `${getKeyValue(cubeData.data[0], 'outdatedTranslationsPercentage') || '0.0'}%`
          },
          {
            title: getKeyValue(cubeData.annotation.measures, 'missingTranslations')?.shortTitle || 'missing translations',
            absolute: getKeyValue(cubeData.data[0], 'missingTranslations'),
            percent: `${getKeyValue(cubeData.data[0], 'missingTranslationsPercentage') || '0.0'}%`
          }
        ]
      ]
    },
  },

  mounted() {
    this.currentContent = 'STANDARD_RESPONSES';
  },
};
</script>

<style scoped>
.response-translation .info-card {
  @apply flex-1 px-4 py-3 border-graphite-500 font-frank;

  border-width: 1px;
}

.response-translation .info-card__title {
  @apply flex-1 text-purple-800 font-frank font-400;

  font-size: 13px;
}

.response-translation .info-card__value-absolute {
  @apply text-purple-500 font-500 text-sm;
}

.response-translation .info-card__value-percent {
  @apply text-graphite-900 font-500 text-xs ml-2 leading-5;
}

.response-translation .response-translation__dropdown {
  @apply w-auto pl-3 pt-0 pb-0;

  padding-top: 2px;
}
</style>